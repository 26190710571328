import currency from "currency.js";

export function formatCurrency(amount?: any) {
  if (amount === 0 || amount === null) {
    return currency(0).format();
  }
  return currency(amount, {
    pattern: `Rp. #`,
    precision: 0,
    separator: ",",
  }).format();
}
