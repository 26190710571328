import React, { useState } from "react";
import { Button, Form } from "antd";
import { Product } from "@libs/types/product";
import PriceInput from "@components/atoms/PriceInput";
import { updateProductInfo } from "@pages/products/logic";

interface ProductPriceSectionProps {
  product: Product;
}

const ProductPriceSection: React.FC<ProductPriceSectionProps> = (props) => {
  const [sending, setSending] = useState<boolean>(false);
  async function onFinish(values: Partial<Product>) {
    console.log(values);
    setSending(true);
    try {
      const updatedValues = {
        defaultAmount: values.defaultAmount
          ? Number(values.defaultAmount)
          : undefined,
        minAmount: values.minAmount ? Number(values.minAmount) : undefined,
        maxAmount: values.maxAmount ? Number(values.maxAmount) : undefined,
      };
      await updateProductInfo(props.product.id, updatedValues);
    } catch (err) {
      console.error(err);
    } finally {
      setSending(false);
    }
  }

  function onFinishFailed() {
    console.log("");
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 16 }}
      initialValues={props.product}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Nilai Rekomendasi"
        name="defaultAmount"
        rules={[
          { required: false, message: "Please input your recommendation!" },
        ]}
      >
        <PriceInput />
      </Form.Item>

      <Form.Item
        label="Nilai Maksimum"
        name="maxAmount"
        rules={[
          { required: false, message: "Please input your recommendation!" },
        ]}
      >
        <PriceInput />
      </Form.Item>

      <Form.Item
        label="Nilai Minimum"
        name="minAmount"
        rules={[
          { required: false, message: "Please input your recommendation!" },
        ]}
      >
        <PriceInput />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button
          disabled={sending}
          loading={sending}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductPriceSection;
