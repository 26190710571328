import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { removeRefreshToken } from "@libs/networking";

interface MenuItem {
  name: string;
  path: string;
  isActive?: boolean;
}

const MenuBar = () => {
  const history = useHistory();
  const initialMenus: MenuItem[] = [
    {
      name: "Dashboard",
      path: "/dashboard",
      isActive: true,
    },
    {
      name: "Produk",
      path: "/products",
    },
    {
      name: "Leads",
      path: "/leads",
    },
    {
      name: "Transaksi",
      path: "/transactions",
    },
    // {
    //   name: "Banners",
    //   path: "banners",
    // },
    // {
    //   name: "Pengguna",
    //   path: "users",
    // },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [menus, setMenus] = useState<MenuItem[]>(initialMenus);

  function logout() {
    removeRefreshToken();
    history.replace("/");
  }

  useEffect(() => {
    const pathName = history.location.pathname;
    const updatedMenu = initialMenus.map((m) => {
      return {
        ...m,
        isActive: pathName.includes(m.path),
      };
    });

    setMenus(updatedMenu);
  }, [history.location]);

  return (
    <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        <button
          className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
        >
          <i className="fas fa-bars" />
        </button>
        {/* <a
          className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
          href="#"
        >
          Sobat Syariah
        </a> */}
        <img src="/ss.png" alt="sobat-syariah" />
        <ul className="md:hidden items-center flex flex-wrap list-none">
          <li className="inline-block relative">
            <a className="text-blueGray-500 block py-1 px-3" href="#pablo">
              <i className="fas fa-bell" />
            </a>
            <div
              className="hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
              style={{ minWidth: "12rem" }}
              id="notification-dropdown"
            >
              <a
                href="#pablo"
                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              >
                Action
              </a>
              <a
                href="#pablo"
                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              >
                Another action
              </a>
              <a
                href="#pablo"
                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              >
                Something else here
              </a>
              <div className="h-0 my-2 border border-solid border-blueGray-100" />
              <a
                href="#pablo"
                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              >
                Seprated link
              </a>
            </div>
          </li>
          <li className="inline-block relative">
            <a
              className="text-blueGray-500 block"
              href="#"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <div className="items-center flex">
                <span className="text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
                  <img
                    alt="..."
                    className="w-full align-middle border-none"
                    src="https://img.icons8.com/material-outlined/24/000000/menu--v3.png"
                  />
                </span>
              </div>
            </a>
          </li>
        </ul>
        <div
          className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded bg-white ${
            mobileMenuOpen ? "" : "hidden"
          }`}
          id="example-collapse-sidebar"
        >
          <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <a
                  className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                  href="#"
                >
                  Sobat Syariah
                </a>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
          </div>
          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            {menus.map((menu, index) => {
              return (
                <li key={`${menu.name}-${index}`} className="items-center">
                  <a
                    className={`${
                      menu.isActive ? "text-pink-500" : "text-gray-500"
                    } hover:text-pink-600 text-xs uppercase py-3 font-bold block`}
                    href="#"
                    onClick={() => history.push(menu.path)}
                  >
                    <i className="fas fa-tv opacity-75 mr-2 text-sm" />
                    {menu.name}
                  </a>
                </li>
              );
            })}
            <li>
              <a
                className={`text-gray-500 hover:text-pink-600 text-xs uppercase py-3 font-bold block`}
                href="#"
                onClick={() => logout()}
              >
                <i className="fas fa-tv opacity-75 mr-2 text-sm" />
                Keluar
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MenuBar;
