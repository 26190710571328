import React from "react";
import { Provider } from "react-redux";
import Routes from "./Routes";
import { store } from "./redux/store";

import "antd/dist/antd.css";
import "./index.css";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
