/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Button, Form, Input } from "antd";
import { Leads, LeadsFormItem } from "@libs/types/Leads";
import Selections from "../molecules/FormItem/Selections";
import PriceInput from "@components/atoms/PriceInput";
import { useDispatch, useSelector } from "react-redux";
import { updateLeads } from "@reduxsaga/reducers/leads/leads-action";
import { StoreType } from "@reduxsaga/type";

interface LeadsFormProp {
  leads: Leads;
}

const { TextArea } = Input;

const LeadsForm: React.FC<LeadsFormProp> = ({ leads }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { sending } = useSelector((state: StoreType) => state.leads);

  let formFields = [];
  try {
    if (leads.additionalFormValues)
      formFields = JSON.parse(leads.additionalFormValues);
  } catch (err) {
    formFields = [];
  }

  const renderField = (field: LeadsFormItem) => {
    switch (field.type) {
      case "text-area":
        return (
          <Form.Item
            key={`leads-item-form-${leads.id}-${field.name}`}
            name={field.name}
            label={field.label}
            rules={[{ required: field.required }]}
            initialValue={field.value}
          >
            <TextArea disabled={sending} />
          </Form.Item>
        );
      case "text":
        return (
          <Form.Item
            key={`leads-item-form-${leads.id}-${field.name}`}
            name={field.name}
            label={field.label}
            rules={[{ required: field.required }]}
            initialValue={field.value}
          >
            <Input disabled={sending} />
          </Form.Item>
        );
      case "text-price":
        return (
          <Form.Item
            key={`leads-item-form-${leads.id}-${field.name}`}
            name={field.name}
            label={field.label}
            rules={[{ required: field.required }]}
            initialValue={field.value}
          >
            <PriceInput />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item
            key={`leads-item-form-${leads.id}-${field.name}`}
            name={field.name}
            label={field.label}
            rules={[{ required: field.required }]}
            initialValue={field.value}
          >
            <Input type="number" />
          </Form.Item>
        );
      case "select":
        return (
          <Selections
            key={`leads-item-form-${leads.id}-${field.name}`}
            field={field}
            disabled={sending}
          />
        );
      default:
        return (
          <div key={`leads-item-form-${leads.id}-${field.name}`}>
            {field.name} {field.type}
          </div>
        );
    }
  };

  const onSubmit = (values: any) => {
    console.log("Success:", values);
    const updatedLeads = { ...leads };
    let formTemplates;
    try {
      if (leads.additionalFormValues) {
        formTemplates = JSON.parse(leads.additionalFormValues);
      }
    } catch (err) {
      console.error(err);
      return;
    }

    const templateValues = formTemplates.map((ft: LeadsFormItem) => {
      return {
        ...ft,
        value: values[ft.name],
      };
    });

    updatedLeads.additionalFormValues = JSON.stringify(templateValues);
    dispatch(updateLeads(leads));
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
        layout="vertical"
        form={form}
      >
        {formFields.map((field: LeadsFormItem) => renderField(field))}

        <Button loading={sending} htmlType="submit" type="primary">
          Update
        </Button>
      </Form>
    </>
  );
};

export default LeadsForm;
