/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAllTransactions } from "@services/transactions.service";
import { TransactionsActionsTypes } from "../reducers/transactions/transactions-type";
import {
  getTransactionsSuccess,
  getTransactionsFail,
} from "@reduxsaga/reducers/transactions/transactions-action";

function* getTransactionsSaga() {
  try {
    // @ts-ignore
    const transactionsResp = yield call(getAllTransactions);
    yield put(getTransactionsSuccess(transactionsResp.data));
  } catch (err) {
    yield put(getTransactionsFail());
  }
}

function* transactionsSaga() {
  yield all([
    takeLatest(TransactionsActionsTypes.GET_TRANSACTIONS, getTransactionsSaga),
  ]);
}

export default transactionsSaga;
