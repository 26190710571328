export type NetworkMethod = "GET" | "POST" | "PUT" | "DELETE";
export async function request(
  url: string,
  method: NetworkMethod,
  body?: any,
  headers?: any
): Promise<any> {
  const defaultHeader = {
    "Content-Type": "application/json",
  };
  const configs = {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: headers ? headers : defaultHeader,
  };

  return fetch(url, configs)
    .then((response) => response.json())
    .then((data) => Promise.resolve(data))
    .catch((err) => Promise.reject(err));
}

export class RequestInstance {
  constructor(baseUrl: string, defaultHeader: any) {
    this.baseUrl = baseUrl;
    this.defaultHeader = defaultHeader;
  }

  private baseUrl = "";
  private defaultHeader = {};

  getBaseURL() {
    return this.baseUrl;
  }

  get(url: string, headers?: any) {
    const usedHeaders = {
      ...this.defaultHeader,
      ...headers,
    };
    console.log(usedHeaders);
    return request(`${this.baseUrl}${url}`, "GET", undefined, usedHeaders);
  }

  post(url: string, body?: any, headers?: any) {
    const usedHeaders = {
      ...this.defaultHeader,
      ...headers,
    };
    return request(`${this.baseUrl}${url}`, "POST", body, usedHeaders);
  }

  put(url: string, body?: any, headers?: any) {
    const usedHeaders = {
      ...this.defaultHeader,
      ...headers,
    };
    return request(`${this.baseUrl}${url}`, "PUT", body, usedHeaders);
  }

  delete(url: string, body?: any, headers?: any) {
    const usedHeaders = {
      ...this.defaultHeader,
      ...headers,
    };
    return request(`${this.baseUrl}${url}`, "DELETE", body, usedHeaders);
  }

  postFile(url: string, file: File): Promise<{ name: string; url: string }> {
    const formData = new FormData();
    formData.append("image", file);

    // const usedHeaders = {
    //   "Content-Type": "multipart/form-data",
    //   ...headers,
    // };

    const requestOptions = {
      method: "POST",
      body: formData,
      // redirect: "follow",
    };
    return fetch(`${this.baseUrl}${url}`, requestOptions)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => console.log("error", error));
  }
}
