import { Transactions } from "@libs/types/Transactions";
import { Button, Form, Select, Input, notification } from "antd";
import React, { useState } from "react";
import { updateTransactionStatusInfo } from "./logic";

const { Option } = Select;

interface TransactionsStatusSectionProps {
  transactions: Transactions;
}

const TransactionsStatusSection: React.FC<TransactionsStatusSectionProps> = (
  props
) => {
  const [sending, setSending] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const onChangeStatus = (event: any) => {
    if (event === "failed") {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  };
  const openNotificationWithIcon = (
    type: string,
    message: string,
    err: string
  ) => {
    if (type == "success" || type == "warning")
      notification[type]({ message: message, description: err });
    return;
  };

  const onFinish = async (values: Partial<Transactions>) => {
    try {
      setSending(true);
      await updateTransactionStatusInfo(props.transactions.id, values);
      openNotificationWithIcon(
        "success",
        "Status updated sucessfully",
        "Updated Success"
      );
    } catch (err) {
      console.error(err);
      openNotificationWithIcon("error", "Status update error", `${err}`);
    } finally {
      setSending(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={props.transactions}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="Status" name="status">
        <Select
          showSearch
          autoClearSearchValue
          defaultActiveFirstOption
          placeholder="Select a status"
          defaultValue="awaiting_fund"
          optionFilterProp="children"
          onChange={onChangeStatus}
        >
          <Option value="awaiting_fund">AWAITING_FUND</Option>
          <Option value="process">PROCESS</Option>
          <Option value="failed">FAILED</Option>
          <Option value="completed">COMPLETED</Option>
          <Option value="funding_claimed">FUNDING_CLAIMED</Option>
          <Option value="funded">FUNDED</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Comment" name="comment">
        <Input.TextArea required={isRequired} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button
          disabled={sending}
          loading={sending}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TransactionsStatusSection;
