import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Signin from "@pages/Signin";
import SSDashboardLayout from "./layouts/DashboardLayout";
import ProductListing from "@pages/products/ProductListing";
import ProductDetail from "@pages/products/ProductDetail";
import AuthProvider from "./layouts/AuthProvider";
import LeadsListing from "@pages/leads/LeadsListing";
import TransactionsListing from "@pages/transactions/TransactionsListing";
import TransactionsDetail from "@pages/transactions/TransactionsDetail";

const Routes: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route exact path="/">
            <Signin />
          </Route>
          <Route path="/dashboard">
            <SSDashboardLayout title={"Dashboard"}></SSDashboardLayout>
          </Route>
          <Route exact path="/products">
            <SSDashboardLayout title={"Products"}>
              <ProductListing />
            </SSDashboardLayout>
          </Route>
          <Route path="/products/:id">
            <SSDashboardLayout title={"Product Detail"}>
              <ProductDetail />
            </SSDashboardLayout>
          </Route>

          <Route path="/leads">
            <SSDashboardLayout title={"Leads"}>
              <LeadsListing />
            </SSDashboardLayout>
          </Route>

          <Route exact path="/transactions">
            <SSDashboardLayout title={"Transactions"}>
              <TransactionsListing />
            </SSDashboardLayout>
          </Route>
          <Route path="/transactions/:id">
            <SSDashboardLayout title={"Transactions Detail"}>
              <TransactionsDetail />
            </SSDashboardLayout>
          </Route>
        </Switch>
      </AuthProvider>
    </Router>
  );
};

export default Routes;
