import { Tag } from "antd";
import React from "react";

interface LeadsStatusProp {
  status: string;
}

const LeadsStatus: React.FC<LeadsStatusProp> = ({ status }) => {
  switch (status) {
    case "PROCESSING":
      return <Tag color="yellow">{status}</Tag>;
    case "AWAITING_USER_AGREEMENT":
      return <Tag color="blue">{status}</Tag>;
    case "USER_AGREE":
      return <Tag color="blue">{status}</Tag>;
    case "ON_PARTNER_PROCESS":
      return <Tag color="yellow">{status}</Tag>;
    case "FAILED":
    case "REJECTED":
    case "CANCELLED":
      return <Tag color="red">{status}</Tag>;
    case "COMPLETED":
      return <Tag color="green">{status}</Tag>;
    default:
      return <></>;
  }
};

export default React.memo<LeadsStatusProp>(LeadsStatus);
