import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Transactions } from "@libs/types/Transactions";
import { formatCurrency } from "@utilities/currency.utilities";
import {
  Col,
  Row,
  Divider,
  Select,
  Form,
  notification,
  Button,
  Input,
  Tag,
} from "antd";

import { updateTransactionStatusInfo } from "./logic";
import { getTransactions } from "@reduxsaga/reducers/transactions/transactions-action";

interface TransactionsViewProps {
  transactions: Transactions;
}

const { Option } = Select;

const DescriptionItem = ({
  title,
  content,
}: {
  title: string;
  content: JSX.Element | string;
}) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const TransactionsView: React.FC<TransactionsViewProps> = (props) => {
  const [sending, setSending] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onChangeStatus = (event: any) => {
    if (event === "failed") {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  };
  const openNotificationWithIcon = (
    type: string,
    message: string,
    err: string
  ) => {
    if (type == "success" || type == "warning")
      notification[type]({ message: message, description: err });
    return;
  };

  const onFinish = async (values: Partial<Transactions>) => {
    try {
      setSending(true);
      await updateTransactionStatusInfo(props.transactions.id, values);
      openNotificationWithIcon(
        "success",
        "Status updated sucessfully",
        "Updated Success"
      );
      dispatch(getTransactions());
    } catch (err) {
      console.error(err);
      openNotificationWithIcon("error", "Status update error", `${err}`);
    } finally {
      setSending(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  let tagStatus;
  let colorStatus;
  switch (props.transactions.status) {
    case "funded":
      tagStatus = "FUNDED";
      colorStatus = "orange";
      break;
    case "process":
      tagStatus = "PROCESS";
      colorStatus = "geekblue";
      break;
    case "completed":
      tagStatus = "COMPLETED";
      colorStatus = "green";
      break;
    case "funding_claimed":
      tagStatus = "FUNDING CLAIMED";
      colorStatus = "gold";
      break;
    case "failed":
      tagStatus = "FAILED";
      colorStatus = "red";
      break;
    default:
      tagStatus = "AWAITING FUND";
      colorStatus = "blue";
      break;
  }
  // if (props.transactions.status === "funded") {
  // } else if (props.transactions.status === "process") {
  // } else if (props.transactions.status === "completed") {
  // } else if (props.transactions.status === "funding_claimed") {
  // } else if (props.transactions.status === "failed") {
  // } else {
  // }

  return (
    <>
      <p
        className="site-description-item-profile-p"
        style={{ marginBottom: 24 }}
      >
        Detail Transaksi
      </p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Entity"
            content={`${props.transactions.entityType}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Detail Name"
            content={`${props.transactions.detailName}`}
          />
        </Col>
      </Row>
      <p
        className="site-description-item-profile-p"
        style={{ marginBottom: 24 }}
      >
        Detail Biaya
      </p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Deskripsi Biaya"
            content={`${formatCurrency(props.transactions.detailAmount)}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Status"
            content={<Tag color={`${colorStatus}`}>{`${tagStatus}`}</Tag>}
          />
        </Col>
      </Row>
      <Divider>Informasi Status</Divider>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={props.transactions}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Status" name="status">
          <Select
            showSearch
            autoClearSearchValue
            defaultActiveFirstOption
            placeholder="Select a status"
            defaultValue="awaiting_fund"
            optionFilterProp="children"
            onChange={onChangeStatus}
          >
            <Option value="awaiting_fund">AWAITING FUND</Option>
            <Option value="process">PROCESS</Option>
            <Option value="failed">FAILED</Option>
            <Option value="completed">COMPLETED</Option>
            <Option value="funding_claimed">FUNDING CLAIMED</Option>
            <Option value="funded">FUNDED</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Comment" name="comment">
          <Input.TextArea required={isRequired} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
          <Button
            disabled={sending}
            loading={sending}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TransactionsView;
