import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import GenericTable, {
  GenericTableColumn,
} from "@components/molecules/GenericTable";
import { Leads } from "@libs/types/Leads";
import { formatDate } from "@utilities/date.utilities";
import LeadsView from "./LeadsView";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeads,
  setLeadTarget,
} from "@reduxsaga/reducers/leads/leads-action";
import { StoreType } from "@reduxsaga/type";
import { isEmpty } from "lodash";
import LeadsStatus from "@components/atoms/LeadsStatus";

const LeadsListing: React.FC = () => {
  const dispatch = useDispatch();
  const {
    leads,
    loading,
    actionTarget: target,
  } = useSelector((state: StoreType) => state.leads);

  const columns: GenericTableColumn<Leads>[] = [
    {
      title: "Nama Depan",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Nama Belakang",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (statusText: string) => <LeadsStatus status={statusText} />,
    },
    {
      title: "Produk",
      dataIndex: "product.name",
      key: "product.name",
      render: (_, record: Leads) => <span>{`${record?.product?.name}`}</span>,
    },
    {
      title: "Didaftarkan",
      key: "createdAt",
      render: (_, record) => <span>{`${formatDate(record.createdAt)}`}</span>,
    },
    {
      title: "Action",
      render(_, record) {
        return <Button onClick={() => openLeadsDetail(record)}>Detail</Button>;
      },
    },
  ];

  function openLeadsDetail(record: Leads) {
    dispatch(setLeadTarget(record));
    // setTarget(record);
  }

  function cleanTarget() {
    dispatch(setLeadTarget());
  }

  useEffect(() => {
    dispatch(getLeads());
  }, []);

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={leads}
        columns={columns}
        rowKey={(it: Leads) => `${it.id}-leads`}
      />
      <Drawer
        title="Informasi Lead"
        placement={"right"}
        width={900}
        onClose={() => {
          console.log("on close");
          cleanTarget();
        }}
        visible={!isEmpty(target)}
      >
        {target && <LeadsView leads={target} />}
      </Drawer>
    </>
  );
};

export default LeadsListing;
