/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

let composeEnhancers = compose;
if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
  //@ts-ignore
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

import reducer from "./reducers";
import sagas from "./sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// then run the saga
sagaMiddleware.run(sagas);
