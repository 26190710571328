/* eslint-disable @typescript-eslint/no-explicit-any */
import { Leads } from "@libs/types/Leads";
import {
  GetLeadsEvent,
  GetLeadsEventFail,
  GetLeadsEventSuccess,
  LeadsActionTypes,
  LeadsRequestOpts,
  SetLeadTargetEvent,
  UpdateLeadsEvent,
  UpdateLeadsFail,
  UpdateLeadsSuccess,
} from "./leads-type";

export function getLeads(opts?: LeadsRequestOpts): GetLeadsEvent {
  return {
    type: LeadsActionTypes.GET_LEADS,
    payload: {
      opts,
    },
  };
}

export function getLeadsSuccess(leads: Leads[]): GetLeadsEventSuccess {
  return {
    type: `${LeadsActionTypes.GET_LEADS}_SUCCESS`,
    payload: leads,
  };
}

export function getLeadsFail(err?: any): GetLeadsEventFail {
  return {
    type: `${LeadsActionTypes.GET_LEADS}_FAIL`,
    payload: {
      errorMessage: err?.message ? err.message : "",
    },
  };
}

export function setLeadTarget(lead?: Leads): SetLeadTargetEvent {
  return {
    type: LeadsActionTypes.SET_LEAD_TARGET,
    payload: lead,
  };
}

export function updateLeads(leads: Leads): UpdateLeadsEvent {
  return {
    type: LeadsActionTypes.UPDATE_LEADS,
    payload: leads,
  };
}

export function updateLeadsSuccess(leads: Leads): UpdateLeadsSuccess {
  return {
    type: `${LeadsActionTypes.UPDATE_LEADS}_SUCCESS`,
    payload: leads,
  };
}

export function updateLeadsFail(errorMessage: string): UpdateLeadsFail {
  return {
    type: `${LeadsActionTypes.UPDATE_LEADS}_FAIL`,
    payload: {
      errorMessage,
    },
  };
}
