import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ProductInfoSection from "@pages/products/ProductInfoSection";
import { Tabs } from "antd";
import ProductPriceSection from "./ProductPriceSection";
import ProductFeeSection from "@pages/products/ProductFeeSection";
import useProductDetail from "@libs/hooks/useProductDetail";

const ProductDetail: React.FC<RouteComponentProps> = (props) => {
  const {
    data: product,
    loading,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useProductDetail(Number(props.match.params.id));

  return (
    <div>
      {!loading && product && (
        <Tabs>
          <Tabs.TabPane tab={"Product Info"} key={"info"}>
            <ProductInfoSection product={product} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Konfigurasi Nilai"} key={"amount"}>
            <ProductPriceSection product={product} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Konfigurasi Fee"} key={"fee"}>
            <ProductFeeSection product={product} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default withRouter(ProductDetail);
