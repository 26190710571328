import { getIdToken } from "@libs/networking";
import { Paginated } from "@libs/types/Paginated";
import { SWRConnector } from "@libs/types/SWRConnector";
import { Transactions } from "@libs/types/Transactions";
import { getManagementApi } from "@utilities/management-api";

const managementApi = getManagementApi();

export async function getAllProducts(): Promise<Paginated<Transactions>> {
  return await managementApi.get("/transactions", {
    Authorization: getIdToken(),
  });
}

export async function getAllTransactions(): Promise<Paginated<Transactions>> {
  return await managementApi.get(`/transactions?per_page=9999`, {
    Authorization: getIdToken(),
  });
}

export async function updateTransactionStatus(
  id: number,
  data: Partial<Transactions>
) {
  await managementApi.put(`/transactions/${id}/status`, data, {
    Authorization: getIdToken(),
  });
}

export function fetchTransactions(): SWRConnector<Paginated<Transactions>> {
  return {
    cacheKey: "/management/v2/transactions",
    fetch(): Promise<Paginated<Transactions>> {
      return managementApi.get("/transactions?limit=999", {
        Authorization: getIdToken(),
      });
    },
  };
}

export function fetchTransactionsDetail(
  id: number
): SWRConnector<Transactions> {
  return {
    cacheKey: `/management/v2/transactions/${id}`,
    fetch(): Promise<Transactions> {
      return managementApi.get(`/transactions/${id}`, {
        Authorization: getIdToken(),
      });
    },
  };
}
