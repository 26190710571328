import {
  TransactionReducerType,
  TransactionsActions,
  TransactionsActionsTypes,
} from "./transactions-type";

const initialState: TransactionReducerType = {
  transactions: [],
};

const reducers = (state = initialState, action: TransactionsActions) => {
  switch (action.type) {
    case TransactionsActionsTypes.GET_TRANSACTIONS:
      return {
        ...state,
        loading: state.transactions.length === 0,
      };
    case TransactionsActionsTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
