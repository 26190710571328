import React from "react";
import { Transactions } from "@libs/types/Transactions";
import { Form, Input, InputNumber } from "antd";
import { updateTransactionStatusInfo } from "./logic";

interface TransactionsInfoSectionProps {
  transactions: Transactions;
}

const TransactionsInfoSection: React.FC<TransactionsInfoSectionProps> = (
  props
) => {
  const onFinish = async (values: Partial<Transactions>) => {
    try {
      await updateTransactionStatusInfo(props.transactions.id, values);
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 16 }}
      initialValues={props.transactions}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="Entity ID" name="entityId">
        <Input readOnly />
      </Form.Item>
      <Form.Item label="Entity Type" name="entityType">
        <Input readOnly />
      </Form.Item>
      <Form.Item label="Nama Detail" name="detailName">
        <Input readOnly />
      </Form.Item>
      <Form.Item label="Deksripsi Biaya" name="detailAmount">
        <InputNumber
          prefix="Rp."
          readOnly
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Form>
  );
};

export default TransactionsInfoSection;
