import { Transactions } from "../../../lib/types/Transactions";

export interface TransactionReducerType {
  transactions: Transactions[];
  loading?: boolean;
}

export enum TransactionsActionsTypes {
  INIT_CLIENT_TRANSACTIONS_STORE = "INIT_CLIENT_TRANSACTIONS_STORE",
  GET_TRANSACTIONS = "GET_TRANSACTIONS",
  GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL",
  SET_TRANSACTIONS = "SET_TRANSACTIONS",
}

export type SetTransactionEvent = {
  type: TransactionsActionsTypes.SET_TRANSACTIONS;
  payload: Transactions[];
};

export type GetTransactionEvent = {
  type: TransactionsActionsTypes.GET_TRANSACTIONS;
};

export type GetTransactionSuccessEvent = {
  type: TransactionsActionsTypes.GET_TRANSACTIONS_SUCCESS;
  payload: Transactions[];
};

export type GetTransactionFailEvent = {
  type: TransactionsActionsTypes.GET_TRANSACTIONS_FAIL;
};

export type TransactionsActions =
  | GetTransactionEvent
  | SetTransactionEvent
  | GetTransactionSuccessEvent
  | GetTransactionFailEvent;
