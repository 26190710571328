// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import useSWR from "swr";
import { fetchTransactionsDetail } from "@services/transactions.service";
const useTransactions = (transactionId: number) => {
  const { data, error, mutate, isValidating } = useSWR(
    fetchTransactionsDetail(transactionId).cacheKey,
    fetchTransactionsDetail(transactionId).fetch
  );
  return { data, error, loading: !data && isValidating, mutate };
};

export default useTransactions;
