import React from "react";
import { useHistory } from "react-router-dom";
import { getFirebaseInstance } from "@libs/firebase.auth";
import { setIdToken, setRefreshToken } from "@libs/networking";

const Signin: React.FC = () => {
  const history = useHistory();
  const firebase = getFirebaseInstance();
  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();

  function onSignin() {
    history.push("/dashboard");
  }

  async function loginGoogle() {
    try {
      await auth.signInWithPopup(provider);
      // https://try2explore.com/questions/10814971
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const idToken = await firebase.auth().currentUser?.getIdToken();
      setIdToken(idToken || "");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      console.log(idToken);
      setRefreshToken(firebase.auth().currentUser?.refreshToken || "");
      onSignin();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img className="w-80 h-60 h-12 mx-auto" src="/ss.png" alt="logo" />
          <h2 className="mt-6 text-xl font-extrabold text-center text-gray-900">
            Admin App
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <div>
            <button
              onClick={loginGoogle}
              className="w-full px-6 py-3 mt-4 font-semibold text-gray-900 bg-white border-2 border-gray-500 rounded-md shadow outline-none hover:bg-yellow-50 hover:border-yellow-400 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline w-4 h-4 mr-3 text-gray-900 fill-current"
                viewBox="0 0 48 48"
                width="48px"
                height="48px"
              >
                <path
                  fill="#fbc02d"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                />
                <path
                  fill="#e53935"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                />
                <path
                  fill="#4caf50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                />
                <path
                  fill="#1565c0"
                  d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                />
              </svg>
              Sign in with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
