import { combineReducers } from "redux";
import transactionsReducers from "./transactions/transaction-reducer";
import leadsReducers from "./leads/leads-reducer";

const rootReducer = combineReducers({
  transactions: transactionsReducers,
  leads: leadsReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
