// Import the functions you need from the SDKs you need
import firebase from "firebase/compat";
import { request } from "@utilities/network";
import { getRefreshToken, setIdToken, setRefreshToken } from "@libs/networking";
import base64 from "base-64";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = null;
if (process.env.REACT_APP_FIREBASE_CONF && !firebaseConfig) {
  console.log(base64.decode(process.env.REACT_APP_FIREBASE_CONF));
  firebaseConfig = JSON.parse(
    base64.decode(process.env.REACT_APP_FIREBASE_CONF)
  );
}

if (firebaseConfig) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

export function getFirebaseInstance() {
  return firebase;
}

export async function refreshIdToken(): Promise<{
  idToken: string;
  refreshToken: string;
}> {
  const webApiKey = "AIzaSyCJtcCFWbMAAbTy8UkfSfncXIcIJFKKqa8";
  const endpoint = `https://securetoken.googleapis.com/v1/token?key=${webApiKey}`;
  const payload = {
    grant_type: "refresh_token",
    refresh_token: getRefreshToken(),
  };

  const resp = await request(endpoint, "POST", payload);
  if (resp.error) {
    return Promise.reject(null);
  }
  console.log(resp);
  setIdToken(resp.id_token);
  setRefreshToken(resp.refresh_token);
  return {
    idToken: resp.id_token,
    refreshToken: resp.refresh_token,
  };
}
