import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericTable, {
  GenericTableColumn,
} from "@components/molecules/GenericTable";
import { Transactions } from "@libs/types/Transactions";

import { Button, Drawer, Tag } from "antd";

import { formatCurrency } from "@utilities/currency.utilities";
import { getTransactions } from "@reduxsaga/reducers/transactions/transactions-action";
import TransactionsView from "./TransactionsView";
import { StoreType } from "@reduxsaga/type";

const TransactionsListing = () => {
  const dispatch = useDispatch();
  const [target, setTarget] = useState<Transactions | null>(null);
  const { transactions, loading } = useSelector(
    (state: StoreType) => state?.transactions
  );

  const columns: GenericTableColumn<Transactions>[] = [
    {
      title: "Entity ID",
      dataIndex: "entityId",
      key: "entityId",
    },
    {
      title: "Tipe Entity",
      dataIndex: "entityType",
      key: "entityType",
    },
    {
      title: "Nama Detail",
      dataIndex: "detailName",
      key: "detailName",
    },
    {
      title: "Deskripsi Biaya",
      dataIndex: "detailAmount",
      key: "detailAmount",
      render: (_, record) => {
        return <span>{`${formatCurrency(record.detailAmount)}`}</span>;
      },
    },
    {
      title: "Status",
      filters: [
        {
          text: "FUNDED",
          value: "funded",
        },
        {
          text: "PROCESS",
          value: "process",
        },
        {
          text: "COMPLETED",
          value: "completed",
        },
        {
          text: "FUNDING CLAIMED",
          value: "funding_claimed",
        },
        {
          text: "FAILED",
          value: "failed",
        },
        {
          text: "AWAITING FUND",
          value: "awaiting_fund",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      key: "status",
      render: (_, record) => {
        if (record.status === "funded") {
          return <Tag color="orange">FUNDED</Tag>;
        } else if (record.status === "process") {
          return <Tag color="geekblue">PROCESS</Tag>;
        } else if (record.status === "completed") {
          return <Tag color="green">COMPLETED</Tag>;
        } else if (record.status === "funding_claimed") {
          return <Tag color="gold">FUNDING CLAIMED</Tag>;
        } else if (record.status === "failed") {
          return <Tag color="red">FAILED</Tag>;
        } else {
          return <Tag color="blue">AWAITING FUND</Tag>;
        }
      },
    },
    {
      title: "Dibuat",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      render(_, record) {
        return (
          <Button onClick={() => openTransactionsDetail(record)}>Detail</Button>
        );
      },
    },
  ];

  function openTransactionsDetail(record: Transactions) {
    setTarget(record);
  }

  function cleanTarget() {
    setTarget(null);
  }

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  useEffect(() => {
    setTarget(null);
  }, [transactions]);

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={transactions}
        columns={columns}
        rowKey={(it: Transactions) => `${it.id}-transactions`}
      />
      <Drawer
        title="Informasi Transaksi"
        placement={"right"}
        width={500}
        onClose={() => {
          console.log("on close");
          cleanTarget();
        }}
        visible={target !== null}
      >
        {target && <TransactionsView transactions={target} />}
      </Drawer>
    </>
  );
};

export default TransactionsListing;
