// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import useSWR from "swr";
import { fetchProducts } from "@services/product.service";
const useProducts = () => {
  const { data, error, mutate, isValidating } = useSWR(
    fetchProducts().cacheKey,
    fetchProducts().fetch
  );
  return { data, error, loading: !data && isValidating, mutate };
};

export default useProducts;
