// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import useSWR from "swr";
import { fetchProductDetail } from "@services/product.service";
const useProducts = (productId: number) => {
  const { data, error, mutate, isValidating } = useSWR(
    fetchProductDetail(productId).cacheKey,
    fetchProductDetail(productId).fetch
  );
  return { data, error, loading: !data && isValidating, mutate };
};

export default useProducts;
