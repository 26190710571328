import React, { useState, useEffect } from "react";
import { Upload, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { uploadFile } from "./logic";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

interface FileUploaderProps {
  onUploadSuccess(fileUrl: string): void;
  value?: string;
  name?: string;
  disabled?: boolean;
}

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  async function handleUpload(e: UploadChangeParam<UploadFile<any>>) {
    setLoading(true);
    try {
      const fileURL = await uploadFile(e);
      setImageUrl(fileURL);
      props.onUploadSuccess(fileURL);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.value && !imageUrl) {
      setImageUrl(props.value);
    }
  }, [props.value]);

  return (
    <>
      <Input
        type="hidden"
        disabled={props.disabled}
        value={props.value}
        name={props.name}
      />
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={() => {
          console.info("on before upload");
        }}
        onChange={(e) => {
          handleUpload(e);
        }}
        style={{ width: "100vh" }}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100wh" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

export default FileUploader;
