import { Transactions } from "@libs/types/Transactions";
import {
  GetTransactionEvent,
  GetTransactionFailEvent,
  GetTransactionSuccessEvent,
  SetTransactionEvent,
  TransactionsActionsTypes,
} from "./transactions-type";

export function getTransactions(): GetTransactionEvent {
  return {
    type: TransactionsActionsTypes.GET_TRANSACTIONS,
  };
}

export function setTransactions(data: Transactions[]): SetTransactionEvent {
  return {
    type: TransactionsActionsTypes.SET_TRANSACTIONS,
    payload: data,
  };
}

export function getTransactionsSuccess(
  data: Transactions[]
): GetTransactionSuccessEvent {
  return {
    type: TransactionsActionsTypes.GET_TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

export function getTransactionsFail(): GetTransactionFailEvent {
  return {
    type: TransactionsActionsTypes.GET_TRANSACTIONS_FAIL,
  };
}
