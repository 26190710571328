import React from "react";
import { Modal, Select, Form, Input } from "antd";
import { Leads, leadsTypes } from "@libs/types/Leads";
import { updateLeads } from "@reduxsaga/reducers/leads/leads-action";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "@reduxsaga/type";

interface LeadsStatusModalProp {
  visible: boolean;
  leads: Leads;
  onCancel(): void;
}

const TextArea = Input.TextArea;

const LeadsStatusModal: React.FC<LeadsStatusModalProp> = ({
  visible,
  onCancel,
  leads,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { sending } = useSelector((state: StoreType) => state.leads);

  const onSuccess = async () => {
    try {
      const values = await form.validateFields();
      const updatedLeads = {
        ...leads,
        status: values.status,
        comments: values.comments,
      };
      dispatch(updateLeads(updatedLeads));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        title="Status Update"
        visible={visible}
        onOk={onSuccess}
        onCancel={onCancel}
        okButtonProps={{
          loading: sending,
          disabled: sending,
        }}
        okText={sending ? "Sending..." : "Ok"}
        cancelButtonProps={{
          disabled: sending,
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name={"status"}
            label={"Status"}
            rules={[{ required: true }]}
            initialValue={leads.status}
          >
            <Select style={{ width: "50%" }} value={leads.status}>
              {leadsTypes.map((type: string) => (
                <Select.Option key={`leads-status-${type}`} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name={"comments"} label={"Komentar"}>
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LeadsStatusModal;
