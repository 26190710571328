import React from "react";
import { Table } from "antd";

export interface GenericTableColumn<T> {
  title: string;
  dataIndex?: string;
  key?: string;
  filters?: { text: string; value: string }[];
  filterSearch?: boolean;
  onFilter?(value: any, record: T): void;
  render?(text: string, record: T): JSX.Element;
}

interface GenericTableProps {
  columns: GenericTableColumn<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: any[];
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?(pagination: any, filters: any, sorter: any): void;
  rowKey?(item: any): string;
}

const GenericTable: React.FC<GenericTableProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onTableChange(pagination: any, filters: any, sorter: any) {
    // TODO: implementation and typing needed
    console.error("onTableChange : Not Implemented");
  }

  return (
    <Table
      dataSource={props.dataSource}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      columns={props.columns}
      loading={props.loading}
      onChange={onTableChange}
      rowKey={props.rowKey}
    />
  );
};

export default GenericTable;
