import { Tabs } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TransactionsInfoSection from "./TransactionsInfoSection";
import useTransactionsDetail from "@libs/hooks/useTransactionsDetail";
import TransactionsStatusSection from "./TransactionsStatusSection";

const TransactionsDetail: React.FC<RouteComponentProps> = (props) => {
  const {
    data: transactions,
    loading,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useTransactionsDetail(Number(props.match.params.id));

  return (
    <div>
      {!loading && transactions && (
        <Tabs>
          <Tabs.TabPane tab={"Transactions Info"} key={"info"}>
            <TransactionsInfoSection transactions={transactions} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Update Status"} key={"status"}>
            <TransactionsStatusSection transactions={transactions} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default withRouter(TransactionsDetail);
