/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import LeadsStatus from "@components/atoms/LeadsStatus";
import { Leads } from "@libs/types/Leads";
import { formatDate } from "@utilities/date.utilities";
import { Col, Row, Divider } from "antd";
import _isEmpty from "lodash/isEmpty";
import LeadsForm from "@components/organism/LeadsForm";
import LeadsStatusModal from "@components/organism/LeadsStatusModal";

interface LeadsViewProps {
  leads: Leads;
}

const DescriptionItem = ({
  title,
  content,
}: {
  title: string;
  content: JSX.Element | string;
}) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const LeadsView: React.FC<LeadsViewProps> = ({ leads }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div>
      <p
        className="site-description-item-profile-p"
        style={{ marginBottom: 24 }}
      >
        Profil
      </p>
      <Row>
        <Col span={12}>
          <DescriptionItem
            title="Full Name"
            content={`${leads.firstName} ${leads.lastName}`}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={leads.email} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Phone" content={`${leads.phone}`} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DescriptionItem
            title="Didaftarkan"
            content={`${formatDate(leads.createdAt)}`}
          />
        </Col>
      </Row>
      <Row>
        {leads.verifiedAt && (
          <Col span={12}>
            <DescriptionItem
              title="Terverifikasi"
              content={`${formatDate(leads.verifiedAt)}`}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col span={12}>
          {leads.status && (
            <DescriptionItem
              title="Status"
              content={
                <div
                  className={"cursor-pointer"}
                  onClick={() => setShowModal(true)}
                >
                  <LeadsStatus status={leads.status} />
                </div>
              }
            />
          )}
        </Col>
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">Partner</p>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Full Name" content={leads.partner.name} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={leads.partner.email} />
        </Col>
      </Row>
      <Divider />
      {leads && <LeadsForm leads={leads} />}

      <LeadsStatusModal
        visible={showModal && !_isEmpty(leads)}
        onCancel={() => setShowModal(false)}
        leads={leads}
      />
    </div>
  );
};

export default LeadsView;
