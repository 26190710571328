import React from "react";
import GenericTable, {
  GenericTableColumn,
} from "@components/molecules/GenericTable";
import { Product } from "@libs/types/product";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import useProducts from "@libs/hooks/useProducts";

const ProductListing = () => {
  const history = useHistory();

  const { data, loading } = useProducts();

  const columns: GenericTableColumn<Product>[] = [
    {
      title: "Judul",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Konten",
      dataIndex: "content",
      key: "content",
      render(text) {
        return <p>{text.substr(0, 75) + "..."}</p>;
      },
    },
    {
      title: "Dibuat",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      render(_, record) {
        return (
          <Button onClick={() => navigateToDetail(record.id)}>Detail</Button>
        );
      },
    },
  ];

  function navigateToDetail(id: number) {
    history.push(`/products/${id}`);
  }

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={data?.data || []}
        columns={columns}
        rowKey={(r) => r.id}
      />
    </>
  );
};

export default ProductListing;
