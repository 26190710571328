import { LeadsFormItem } from "@libs/types/Leads";
import { Form, Select } from "antd";
import React from "react";

interface SelectionsProp {
  field: LeadsFormItem;
  disabled?: boolean;
}

const Selections: React.FC<SelectionsProp> = ({ field, disabled }) => {
  return (
    <Form.Item
      name={field.name}
      label={field.label}
      rules={[{ required: field.required }]}
      initialValue={field.value}
    >
      <Select showSearch disabled={disabled}>
        {field.options?.map((op) => (
          <Select.Option
            key={`selection-${field.name}-${op.value}`}
            value={op.value}
          >
            {op.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Selections;
